/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { Box, Col, Heading, Row, Text } from '@fast-ai/ui-components';
import DrAnimation from '@fast-ai/ui/ZoeDrAnimation';

import { DefaultLayout } from '../layout';
import { CompaniesBrands, Section, Seo } from '../ui';
import {
	ContactSection,
	DrProcessSection,
	DrVideoSection,
} from '../view/landing';
import { RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageDr';

const styles = {
	heroHeading: {
		// fontSize: [7, 7, 8, 9],
		// 49, 85
		fontSize: [6, 7, 8, '64px'],
		lineHeight: 1,
		mb: [2, 2, 3, 3],
		maxWidth: '40rem',
	},
	perex: {
		fontSize: [4, 4, 5],
		maxWidth: '38rem',
		mb: [2, 2, 3, 3],
	},
	actions: {
		display: 'flex',
		mb: [2, 2, 3, 3],
		justifyContent: ['center', 'center', 'flex-start'],
		'a + button': {
			ml: 2,
		},
	},
	bigButton: {
		py: [2, 3],
		px: [3, 4],
	},
};

const DrPage = ({ location, ...rest }) => {
	const translate = useTranslateMessage();
	return (
		<DefaultLayout location={location} {...rest}>
			<Seo title={translate(m.title)} />
			<Section>
				<Row
					sx={{
						alignItems: 'center',
						flexWrap: 'wrap',
						textAlign: ['center', 'center', 'left'],
						py: [5, 5, 6],
					}}
				>
					<Col span={[12, 12, 8, 8]}>
						<Heading sx={styles.heroHeading}>
							<RichFormattedMessage {...m.heading} />
						</Heading>
						<Text as="p" sx={styles.perex}>
							<RichFormattedMessage {...m.perex} />
						</Text>
						<Box
							sx={{
								position: ['static', 'static', 'absolute'],
								bottom: '50%',
								transform: ['none', 'none', 'translateY(50%)'],
								right: 0,
								mx: 'auto',
							}}
						>
							<DrAnimation />
						</Box>
					</Col>
				</Row>
			</Section>
			<DrVideoSection />
			<DrProcessSection
				heading={<RichFormattedMessage {...m.howToHeading} />}
			/>
			<Section variant="stripesSecondary">
				<CompaniesBrands location={location} />
			</Section>
			<ContactSection />
		</DefaultLayout>
	);
};

export default DrPage;
