import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'page.dr.title',
		defaultMessage: 'Deep recommendation',
	},
	heading: {
		id: 'page.dr.heading',
		defaultMessage: 'Deep recommendation',
	},
	perex: {
		id: 'page.dr.perex',
		defaultMessage:
			'Based on <strong>self-learning</strong>, AI models evaluate how visitors behave on the page and we can deliver <strong>personalized products</strong> for each visitor in real-time.',
	},
	howToHeading: {
		id: 'page.dr.howToHeading',
		defaultMessage: 'How to start with Deep recommendation<strong>?</strong>',
	},
});
